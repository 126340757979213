import React from 'react';

import styles from './ElectionArticles.module.css';

interface SkeletonLoaderProps {
  type: 'main' | 'second' | 'row' | 'mobile';
}

const ArticlesSkeletonLoader: React.FC<SkeletonLoaderProps> = ({ type }) => {
  switch (type) {
    case 'main': {
      return (
        <>
          <div className={`${styles.SkeletonImgMain} ${styles.Skeleton}`} />
          <div className={styles.ElectionNightArticleContent}>
            <p className={`${styles.ArticleSkeletonText} ${styles.Skeleton}`} />
            <p className={`${styles.ArticleSkeletonText} ${styles.Skeleton}`} />
            <p className={`${styles.ArticleSkeletonText} ${styles.Skeleton}`} />
            <p className={`${styles.ArticleSkeletonText} ${styles.Skeleton}`} />
            <div className={styles.ElectionNightFlexRow}>
              <p className={`${styles.SkeletonTextAuthorMain} ${styles.Skeleton}`} />
            </div>
          </div>
        </>
      );
    }

    case 'second': {
      return (
        <>
          <div className={`${styles.SkeletonImg2nd} ${styles.Skeleton}`} />
          <div style={{ paddingLeft: '14px', paddingRight: '14px' }}>
            <p className={`${styles.SkeletonTextTitle} ${styles.Skeleton}`} />
            <p className={`${styles.SkeletonTextTitle} ${styles.Skeleton}`} />

            <div style={{ display: 'flex', justifyContent: 'start', height: '100%', alignItems: 'end' }}>
              <p className={`${styles.SkeletonTextAuthor2nd} ${styles.Skeleton}`} />
            </div>
          </div>
        </>
      );
    }
    case 'row': {
      return (
        <>
          <div className={`${styles.SkeletonImg2nd} ${styles.Skeleton}`} />
          <div style={{ paddingLeft: '14px', paddingRight: '14px' }}>
            <p className={`${styles.SkeletonTextTitle} ${styles.Skeleton}`} />
            <p className={`${styles.SkeletonTextTitle} ${styles.Skeleton}`} />

            <div style={{ paddingTop: '32px' }}>
              <p className={`${styles.SkeletonTextAuthor2nd} ${styles.Skeleton}`} />
            </div>
          </div>
        </>
      );
    }

    case 'mobile': {
      return (
        <>
          <div className={styles.ElectionNightFlexGrowColumn}>
            <p className={`${styles.SkeletonTextAuthor2nd} ${styles.Skeleton}`} />
            <p className={`${styles.SkeletonTextAuthor2nd} ${styles.Skeleton}`} />
          </div>
          <div className={`${styles.ElectionNightImageStyle} ${styles.Skeleton}`} />
        </>
      );
    }
    default:
      return (
        <div>
          <div className={styles.ElectionNightFlexGrowColumn}>
            <p className={`${styles.ElectionNightTextParagraph} ${styles.Skeleton}`} />
            <p className={`${styles.ElectionNightTextStyle} ${styles.Skeleton}`} />
          </div>
        </div>
      );
  }
};

export default ArticlesSkeletonLoader;
