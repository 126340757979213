/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import React from 'react';

import NextLink from 'next/link';

import { timeSincePublished } from '@/utils/helpers/timeSincePublished';

import styles from './allPosts.module.css';

export default function Post({ data, index }) {
  /* the goal here is to figure out which items in the grid need to have a "special" 
  class on them to make them extra wide on screen sizes larger than 1200px */
  function calculateIndex(index: number) {
    // Return true for 0, 15, 28, 43, ...
    if (index === 0) {
      return true;
    } else if (index % 28 === 0 || index % 28 === 15) {
      return true;
    } else {
      return false;
    }
  }
  return (
    <NextLink href={`/news/${data?.slug}`}>
      <a className={calculateIndex(index) ? styles.wideItem : undefined} title={data?.title}>
        <div className={styles.post}>
          <img
            src={
              data?.image?.url
                ? `${data.image.url}?ar=16%3A6&fit=crop&crop=faces&w=3840&auto=format&ixlib=react-9.3.0`
                : '/images/post-teaser-fallback.png'
            }
          />
          <div className={styles.textContainer}>
            <span>{data?.categories[0]?.name}</span>
            <h3>{data?.title}</h3>
            <p>
              By {data?.author?.name} | {timeSincePublished(data?.date)}
            </p>
          </div>
        </div>
      </a>
    </NextLink>
  );
}
