/* eslint-disable import/no-unresolved */
/* eslint-disable import/order */

import React, { MouseEventHandler } from 'react';

import { useRouter } from 'next/router';

import { SectionLabelV2 } from '@/components/design-system';

import { WpPost } from 'types/wp';
import { timeSincePublished } from '@/utils/helpers/timeSincePublished';

import styles from './Homepage.module.css';

interface HpTopStoriesProps {
  featuredPosts: WpPost[];
}

interface TopStoryProps {
  number?: number;
  post: WpPost;
}

const HomepageTopStories: React.FC<HpTopStoriesProps> = function ({ featuredPosts }) {
  return (
    <div className={styles.topStoriesMain}>
      <SectionLabelV2 section='' title='Top Stories' />
      <div className={styles.topStoriesContainer}>
        {featuredPosts.map(function (post) {
          return <TopStory key={post.id} post={post} />;
        })}
      </div>
    </div>
  );
};

const TopStory: React.FC<TopStoryProps> = function ({ number, post }) {
  const router = useRouter();

  const goTo: MouseEventHandler = function () {
    router.push(`/news/${post.slug}?topStoryPosition=${number}`);
  };
  return (
    <>
      <div className={styles.topStoryContainer} onClick={goTo} title={post.title}>
        <img
          alt={post?.title}
          className={styles.topStoriesImage}
          src={`${post?.image?.url}?ar=1%3A1&fit=crop&crop=faces&w=3840&auto=format&ixlib=react-9.3.0)`}
        />
        <div className={styles.topStoryTextContainer}>
          <h3
            className={styles.topStoryTitle}
            style={{
              margin: 0,
            }}
          >
            {post.title}
          </h3>
          <p
            className={styles.topStoryAuthor}
            style={{
              margin: 0,
            }}
          >
            By {post.author.name} | {timeSincePublished(post?.date)}
          </p>
          <img
            alt={post?.title}
            src={`${post?.image?.url}?ar=16%3A6&fit=crop&crop=faces&w=3840&auto=format&ixlib=react-9.3.0)`}
          />
        </div>
      </div>
      <div className={styles.topStoriesDivider} />
    </>
  );
};

export default HomepageTopStories;
