/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import * as React from 'react';

import { useBreakpoints } from '@/hooks/useBreakpoints';

import styles from './ElectionDay.module.css';

const ElectionDayNav = ({ activeSection }) => {
  const { isMobile, isPhablet, isTablet } = useBreakpoints();
  const [mobileView, setMobileView] = React.useState(false);
  const handleScroll = (e, target) => {
    e.preventDefault();
    document.getElementById(target).scrollIntoView({ behavior: 'smooth' });
  };

  React.useEffect(() => {
    setMobileView(isTablet || isPhablet || isMobile);
  }, [isTablet, isPhablet, isMobile]);

  const handleScrollToTop = (e) => {
    e.preventDefault();
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <nav className={styles.electionNavContainer}>
      <ul className={styles.electionNavList}>
        <li className={styles.electionNavImg}>
          <a href='#' onClick={handleScrollToTop}>
            <div className={styles.svgWrapper}>
              <img src='/svg/flags/dark.svg' style={{ height: '20px' }} />
            </div>
          </a>
        </li>
        {!mobileView && <span style={{ opacity: '0.56' }}>|</span>}
        <li className={`${activeSection === 'news' ? styles.electionNavItemActive : styles.electionNavItem}`}>
          <a href='#' onClick={(e) => handleScroll(e, 'news')}>
            {mobileView ? 'News' : 'News'}
          </a>
        </li>
        {/* <li className={`${activeSection === 'live-blog' ? styles.electionNavItemActive : styles.electionNavItem}`}>
          <a href='#' onClick={(e) => handleScroll(e, 'live-blog')}>
            {mobileView ? 'Live Blog' : 'Live Blog'}
          </a>
        </li> */}
      </ul>
    </nav>
  );
};

export default ElectionDayNav;
